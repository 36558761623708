import React from "react"
import FooterGallery from "../components/footer-gallery"
import Layout from "../components/layout"
import PropertyMap from "../components/property/property-map"
import SEO from "../components/seo"

const PropertyPage = ({ data }) => {
    const { baseImage, baseRichText, footerGallery, propertyItems } = data.wpPage.property_page
    
    return (
        <Layout>
            <SEO title="Property" />
            <PropertyMap baseRichText={baseRichText} baseImage={baseImage} propertyItems={propertyItems} />
            {footerGallery && footerGallery.length > 0 &&
                <FooterGallery gallery={footerGallery} />
            }
        </Layout>
    )
}

export default PropertyPage

export const PropertyQuery = graphql`
    query propertyQuery {
        wpPage(title: { eq: "Property" }) {
            property_page {
                baseRichText
                baseImage {
                    sourceUrl
                }
                footerGallery {
                    sourceUrl
                }
                propertyItems {
                    description
                    image {
                        sourceUrl
                    }
                    mapImage {
                        sourceUrl
                    }
                    name
                    learnMoreLink
                }
            }
        }
    }
`
