import { navigate } from "gatsby"
import React from "react"
import styled from "styled-components"
import { MainButton } from "../../styles/buttons"

const PropertyItem = ({ item, setItem }) => {
    return (
        <Container>
            <BackNav onClick={() => setItem(null)}>{`< ${item.name}`}</BackNav>
            <Image src={item.image.sourceUrl} />
            <Description>{item.description}</Description>
            <a href={item.learnMoreLink} target="_blank" rel="noreferrer">
                <MainButton>
                    LEARN MORE
                </MainButton>
            </a>
        </Container>
    )
}

export default PropertyItem

const Container = styled.div`
    display: grid;
    grid-template-rows: repeat(4, auto);
    gap: 15px;
`
const BackNav = styled.button`
    background: none;
    border: none;
    text-align: left;
    outline: none;
    text-transform: uppercase;
    font-size: 19px;
    cursor: pointer;
`
const Image = styled.img`
    max-width: 250px;
    width: 100%;
`
const Description = styled.p``
