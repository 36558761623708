import React from "react"
import { useState } from "react"
import styled from "styled-components"
import PropertyItem from "./property-item"

export default function PropertyMap({ baseImage, baseRichText, propertyItems }) {
    const [currentPropertyItem, setCurrentPropertyItem] = useState(null)

    return (
        <Wrapper>
            <NavigationWrapper>
                {currentPropertyItem ? (
                    <PropertyItem
                        item={currentPropertyItem}
                        setItem={setCurrentPropertyItem}
                    />
                ) : (
                    <Navigation>
                        <List style={{ margin: 0 }}>
                            {propertyItems.map((item, index) => (
                                <ListItem
                                    key={index}
                                    onClick={() => setCurrentPropertyItem(item)}
                                >
                                    {item.name}
                                </ListItem>
                            ))}
                        </List>
                    </Navigation>
                )}
            </NavigationWrapper>
            <MapWrapper>
                <Map
                    src={
                        currentPropertyItem
                            ? currentPropertyItem.mapImage.sourceUrl
                            : baseImage.sourceUrl
                    }
                    />
                    {!currentPropertyItem &&
                        <RichData dangerouslySetInnerHTML={{ __html: baseRichText}} />
                    }
            </MapWrapper>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    display: grid;
    grid-template-columns: 350px 1fr;
    gap: 50px;
    padding: 20px;

    @media (max-width: 768px) {
        grid-template-columns: 200px 1fr;
        gap: 20px;
    }

    @media (max-width: 700px) {
        grid-template-columns: 100%;
    }
`
const NavigationWrapper = styled.div`
    background: #efefef;
    min-width: 250px;
    height: min-content;
    position: sticky;
    top: 150px;
    padding: 30px 25px;

    @media (max-width: 768px) {
        min-width: initial;
    }

    @media (max-width: 700px) {
        position: relative;
        top: 0;
    }
`
const Navigation = styled.div``
const MapWrapper = styled.div``
const Map = styled.img`
    /* height: 75vh; */
    object-fit: contain;

    @media (max-width: 1024px) {
        height: 50vh;
    }
`
const List = styled.ul`
    margin: 0;
    display: flex;
    flex-direction: column;
`
const ListItem = styled.button`
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 400;
    text-align: left;
    margin: 5px 0;

    @media (max-width: 768px) {
        font-size: 16px;
    }
`
const RichData = styled.div`
    margin-top: 20px;
`;